import React from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { pages } from "src/constants";
import { SiteHeader } from "./components/SiteHeader/SiteHeader";
import { SiteFooter } from "./components/SiteFooter/SiteFooter";
import { BubbleText } from "./components/BubbleText/BubbleText";
import { ServiceBlock } from "./components/ServiceBlock/ServiceBlock";

import pikLogo1x from "./assets/pik@1x.png";
import pikLogo2x from "./assets/pik@2x.png";
import lokoLogo1x from "./assets/loko-bank@1x.png";
import lokoLogo2x from "./assets/loko-bank@2x.png";
import knopkaLogo1x from "./assets/knopka@1x.png";
import knopkaLogo2x from "./assets/knopka@2x.png";
import yandexLogo1x from "./assets/yandex@1x.png";
import yandexLogo2x from "./assets/yandex@2x.png";
import sferaLogo1x from "./assets/sfera@1x.png";
import sferaLogo2x from "./assets/sfera@2x.png";
import lifepayLogo1x from "./assets/life-pay@1x.png";
import lifepayLogo2x from "./assets/life-pay@2x.png";
import yashinLogo1x from "./assets/yashin@1x.png";
import yashinLogo2x from "./assets/yashin@2x.png";
import tochkaLogo from "./assets/tochka.svg";
import demoImage1x from "./assets/dashboard@1x.png";
import demoImage2x from "./assets/dashboard@2x.png";

import "./Landing.scss";

const b = cn("landing-page");

const scrollSpeed = {
  human: {
    X: 0.1,
    Y: 0.3,
  },
  BUBBLE: 0.15,
  LIST: 0.5,
};

const scrollSegment = {
  list: {
    TOP: 1000,
    BOTTOM: 1600,
  },
};

let topWrapper: any,
  humanLeft: any,
  humanCenter: any,
  humanRight: any,
  bubbleLeft: any,
  bubbleCenter: any,
  bubbleRight: any,
  servivesList: any;

export class Landing extends React.Component<any> {
  componentDidMount() {
    topWrapper = document.querySelector(".landing-page__top-wrapper");
    humanLeft = topWrapper.querySelector("#human-left");
    humanCenter = topWrapper.querySelector("#human-center");
    humanRight = topWrapper.querySelector("#human-right");
    bubbleLeft = humanLeft.querySelector("#bubble-left");
    bubbleCenter = humanCenter.querySelector("#bubble-center");
    bubbleRight = humanRight.querySelector("#bubble-right");
    servivesList = document.querySelector(".landing-page__services-list");
  }

  handleFormSubmit = (evt: any) => {
    evt.preventDefault();
    function sendUserEmail(email: string) {
      if (email.trim()) {
        const baseURL = "https://buro.app";
        const body = JSON.stringify({ email });
        const fetchOptions = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body,
        };
        fetch(`${baseURL}/api/v1/newsletter/subscribe`, fetchOptions).then((response) => {
          if (response.status === 200) {
            return response.json().then((answer) => {});
          } else {
            alert("Не удалось отправить ваш email");
          }
        });
      } else {
        alert("Введите email!");
      }
    }

    const input: any = document.querySelector("#subs-input");
    sendUserEmail(input.value);
  };

  handleScroll = () => {
    const scrollRef: any = this.refs.page;
    const scrollValue = scrollRef.scrollTop;

    // если мы проскроллили до 400 пкс, останавливаем параллакс
    if (scrollValue < 400) {
      humanLeft.style.transform = `translate(-${scrollValue * scrollSpeed.human.X}px, -${
        scrollValue * scrollSpeed.human.Y
      }px)`;
      bubbleLeft.style.transform = `translateY(${scrollValue * scrollSpeed.BUBBLE}px)`;
      humanCenter.style.transform = `translateY(-${scrollValue * scrollSpeed.human.Y}px)`;
      bubbleCenter.style.transform = `translateY(${scrollValue * scrollSpeed.BUBBLE}px)`;
      humanRight.style.transform = `translate(${scrollValue * scrollSpeed.human.X}px, -${
        scrollValue * scrollSpeed.human.Y
      }px)`;
      bubbleRight.style.transform = `translateY(${scrollValue * scrollSpeed.BUBBLE}px)`;

      // доскроллили до списка, запускаем для списка
    } else if (scrollValue > scrollSegment.list.TOP && scrollValue < scrollSegment.list.BOTTOM) {
      servivesList.style.transform = `translateX(-${(scrollValue - scrollSegment.list.TOP) * scrollSpeed.LIST}px)`;
    }
  };

  render() {
    return (
      <div className={b()} onScroll={this.handleScroll} ref="page">
        <Helmet>
          <title>Buro</title>
        </Helmet>

        <SiteHeader />

        {/* Главный экран */}
        <div className="main-container">
          <div className={b("top-wrapper")}>
            <div className={b("human-container", { left: true })} id="human-left">
              <BubbleText className={b("bubble-text")} id="bubble-left">
                Отправил документы в ФНС за 5 минут!
              </BubbleText>
            </div>
            <div className={b("human-container", { center: true })} id="human-center">
              <BubbleText className={b("bubble-text")} id="bubble-center">
                Так удобно, супер!
              </BubbleText>
            </div>
            <div className={b("human-container", { right: true })} id="human-right">
              <BubbleText className={b("bubble-text")} id="bubble-right">
                С электронной подписью у меня
                <br /> так много новых возможностей!
              </BubbleText>
            </div>
          </div>
          <div className={b("text-wrapper")}>
            <h1 className={b("title")}>Система онлайн-регистрации бизнеса при&nbsp;помощи электронной подписи</h1>
            <Link to={pages.companyRegistration} className={`site-button site-button--long ${b("top-button")}`}>
              Зарегистрироваться
            </Link>
          </div>
        </div>

        {/* Список клиентов */}
        <section className={b("section")}>
          <div className="main-container">
            <h2 className={b("subtitle")}>Сервис для профессионалов</h2>
            <p className={b("description")}>Уже используют Бюро для работы со своими клиентами</p>
            <ul className={`${b("list")} ${b("clients-list")}`}>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={pikLogo1x} alt="Группа компаний ПИК." width="52" height="53" srcSet={`${pikLogo2x} 2x`} />
              </li>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={lokoLogo1x} alt="ЛокоБанк." width="100" height="28" srcSet={`${lokoLogo2x} 2x`} />
              </li>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={knopkaLogo1x} alt="Кнопка." width="100" height="20" srcSet={`${knopkaLogo2x} 2x`} />
              </li>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={yandexLogo1x} alt="Яндекс Такси." width="94" height="20" srcSet={`${yandexLogo2x} 2x`} />
              </li>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={sferaLogo1x} alt="Сфера." width="89" height="40" srcSet={`${sferaLogo2x} 2x`} />
              </li>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={lifepayLogo1x} alt="Сфера." width="52" height="52" srcSet={`${lifepayLogo2x} 2x`} />
              </li>
              <li className={`clients-item ${b("clients-item")}`}>
                <img src={yashinLogo1x} alt="Сфера." width="95" height="13" srcSet={`${yashinLogo2x} 2x`} />
              </li>
              <li className={`clients-item clients-item--text ${b("clients-item")}`}>
                <p>и ещё 200+</p>
              </li>
            </ul>
          </div>
        </section>

        {/* Список услуг */}
        <section className={b("section", { services: true })}>
          <h2 className={b("subtitle")}>Что можно делать в Бюро?</h2>
          <div className="main-container main-container_long">
            <ul className={`${b("list")} ${b("services-list")}`}>
              <li className={b("services-item")}>
                <ServiceBlock checked="true">Регистрация ИП</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock checked="true">Регистрация ООО</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock checked="true">Внесение изменений в ООО</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock checked="true">Внесение изменений в ИП</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock checked="true">Закрытие ИП</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock checked="true">Ликвидация ООО</ServiceBlock>
              </li>
            </ul>
          </div>
        </section>

        <section className={b("section", { "how-it-works": true })} id="how-it-works-section">
          <div className="main-container">
            <div className="how-it-works-container">
              <div className="right-column">
                <h2 className={b("subtitle", { left: true })}>Как это работает?</h2>
                <p className={b("description", { left: true })}>
                  Бюро объединяет все действия в один быстрый и удобный процесс. Среднее время обработки одной заявки —
                  10 минут.
                </p>
                <Link to={pages.companyRegistration} className={`site-button site-button--long ${b("middle-button")}`}>
                  Зарегистрироваться
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className={b("section")}>
          <h2 className={b("subtitle")}>Все процессы в одном окне:</h2>
          <div className={classNames("main-container main-container_short", b("demo-wrapper"))}>
            <img
              className={b("demo-image")}
              src={demoImage1x}
              srcSet={`${demoImage2x} 2x`}
              alt="Главный экран Бюро."
              width="1200"
              height="722"
            />
            <ul className={b("demo-list")}>
              <li className={b("demo-item", { first: true })}>
                <BubbleText position="bottom-right">Все заявки в&nbsp;одном месте</BubbleText>
              </li>
              <li className={b("demo-item", { second: true })}>
                <BubbleText position="top-right">Дополнительные возможности</BubbleText>
              </li>
              <li className={b("demo-item", { third: true })}>
                <BubbleText position="top-left">
                  Удобно отслеживать <br />
                  статус регистрации
                </BubbleText>
              </li>
              <li className={b("demo-item", { fourth: true })}>
                <BubbleText>
                  Отвечаем в&nbsp;чате за&nbsp;5&nbsp;минут, <br />
                  если возникла проблема
                </BubbleText>
              </li>
            </ul>
          </div>
          <div className="main-container main-container_long">
            <ul className={`${b("list")} ${b("services-list", { wrap: true })}`}>
              <li className={b("services-item")}>
                <ServiceBlock>Формирование документов для гос. регистрации</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock>Выпуск квалифицированной электронной подписи</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock>Подписание документов электронной подписью</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock>Подписание документов электронной подписью</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock>Отправка документов в ФНС одним кликом</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock>Отслеживание статуса регистрации</ServiceBlock>
              </li>
            </ul>
          </div>
        </section>

        <div className="main-container main-container_short">
          <section className={b("section", { features: true })}>
            <div className="right-column right-column_short">
              <ServiceBlock>Передача заявки в банк одним кликом</ServiceBlock>
              <ul className={b("list")}>
                <li>+ прямая интеграция с банком;</li>
                <li>+ не нужно заполнять данные клиента еще раз;</li>
                <li>+ нажатием одной кнопки заявка сразу попадает в банк на обработку.</li>
              </ul>
              <div className={b("logos-wrapper")}>
                <img src={sferaLogo1x} alt="Сфера." width="89" height="40" srcSet={`${sferaLogo2x} 2x`} />
                <img src={tochkaLogo} alt="Сфера." width="104" height="40" />
              </div>
            </div>
          </section>

          {/* Отзывы */}
          <section className={b("section")}>
            <h2 className={b("subtitle")}>Кто уже работал с Бюро?</h2>
            <ul className={`${b("list")} ${b("reviews-list")}`}>
              <li className={b("reviews-item")}>
                <BubbleText signed authorName="LORD">
                  Сервис Бюро позволил выйти на&nbsp;новый уровень, благодаря сервису мы&nbsp;ускорили работу&nbsp;юр.
                  отдела и&nbsp;автоматизировали бизнес процессы с&nbsp;клиентами.
                </BubbleText>
              </li>
              <li className={b("reviews-item")}>
                <BubbleText signed authorName="Кнопка">
                  Очень выгодные условия для клиента, экономит время, а&nbsp;так&nbsp;же деньги за&nbsp;счёт отсутствия
                  государственной пошлины. Самостоятельно отправляем документы в&nbsp;налоговую, сервис синхронизирован
                  с&nbsp;ФИАС, очень удобно при проверке адреса.
                </BubbleText>
              </li>
              <li className={b("reviews-item")}>
                <BubbleText signed authorName="Арбитр">
                  Сервисом Бюро я&nbsp;пользуюсь 3&nbsp;года, спасибо разработчикам за&nbsp;то, что прислушиваются
                  к&nbsp;пользователям. Сервис улучшается, специалисты вежливые и&nbsp;всегда оперативно помогают
                  отвечая на&nbsp;вопросы.
                </BubbleText>
              </li>
              <li className={b("reviews-item", { long: true, "with-padding": true })}>
                <BubbleText signed authorName="Такси Ритм">
                  Бюро&nbsp;&mdash; очень удобный сервис по&nbsp;открытию ИП. Мы&nbsp;значительно увеличили
                  количество&nbsp;ИП среди водителей. Сервис значительно сокращает время подачи заявки
                  на&nbsp;открытие&nbsp;ИП, позволяет не&nbsp;обращаться в&nbsp;налоговые органы по&nbsp;месту
                  регистрации, работать в&nbsp;режиме &laquo;одного окна&raquo;, что сильно упрощает работу
                  с&nbsp;водителями и&nbsp;экономит большое количество времени. Спасибо!
                </BubbleText>
              </li>
              <li className={b("reviews-item", { "with-padding": true })}>
                <BubbleText signed authorName={`Вмассиве (ГК "ПИК")`}>
                  Мы&nbsp;без вас как без рук
                </BubbleText>
              </li>
            </ul>
          </section>
        </div>

        {/* Цена */}
        <section className={b("section", { price: true })} id="price-section">
          <div className="main-container">
            <div className="price-container">
              <div className={`${b("price-bubble")} price-bubble`}>
                <p className="price-bubble__wrapper">
                  <b className="price-bubble__value">1 349 ₽</b>{" "}
                  <span className="price-bubble__description">— единая цена на все регистрационные действия.</span>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={b("section", { subs: true })}>
          <div className="main-container main-container_short">
            <div className="subs-container">
              <div className="left-column">
                <h2 className={b("subtitle", { left: true })}>Будьте в курсе</h2>
                <p className={b("description", { left: true })}>
                  Мы&nbsp;постоянно развиваемся, собираем обратную связь, открываем новые возможности. Мы&nbsp;уже
                  запланировали новые функции, подпишитесь, чтобы узнать первым.
                </p>
                <form className={b("subs-form")} onSubmit={this.handleFormSubmit}>
                  <input type="email" placeholder="Ваш e-mail" name="email" id="subs-input" required />
                  <button className="site-button" id="subs-button" type="submit">
                    Подписаться
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="main-container">
            <ul className={`${b("list")} ${b("services-list")}`}>
              <li className={b("services-item")}>
                <ServiceBlock empty="true">Подача уведомления УСН</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock empty="true">Регистрация сделок с недвижимостью</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <ServiceBlock empty="true">Регистрация онлайн касс</ServiceBlock>
              </li>
              <li className={b("services-item")}>
                <p className={b("round-label")}>Скоро</p>
              </li>
            </ul>
          </div>
        </section>

        <SiteFooter />
      </div>
    );
  }
}
